<template>
  <div>
    <KTCodePreview v-bind:title="'Thông tin chương trình chiết khấu'">
      <template v-slot:preview>
        <b-form lazy-validation class="row">
          <!-- Input session -->
          <b-container>
            <b-row>
              <b-col cols="6">
                <b-row>
                  <b-col cols="12">
                    <b-form-group class="required-control">
                      <label>Tên chương trình:</label>
                      <b-form-input
                        size="sm"
                        v-model="$v.mainModel.name.$model"
                        placeholder="Nhập tên"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group>
                      <label
                        >Cửa hàng áp dụng (để trống nếu áp dụng toàn hệ
                        thống):</label
                      >
                      <b-input-group>
                        <simple-multi-select
                          v-model="mainModel.storeIds"
                          :options="stores"
                          :placeholder="'Chọn cửa hàng'"
                          :option-label="'name'"
                        >
                        </simple-multi-select>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group>
                      <label>Mô tả:</label>
                      <b-form-textarea
                        v-model="mainModel.description"
                        placeholder="Nhập mô tả"
                        rows="5"
                        :max-length="255"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="6">
                <b-row>
                  <b-col cols="6">
                    <b-form-group class="required-control">
                      <label>Ngày bắt đầu:</label>
                      <date-picker
                        placeholder="Từ ngày"
                        class="form-control form-control-sm"
                        :config="dpConfigs.date"
                        v-model="mainModel.startAt"
                      ></date-picker>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label>Đến ngày</label>
                      <date-picker
                        placeholder="Đến ngày"
                        class="form-control form-control-sm"
                        :config="dpConfigs.date"
                        v-model="mainModel.endAt"
                      ></date-picker>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row style="margin-bottom: 1.75rem">
                  <b-col cols="6">
                    <b-row>
                      <b-col md="6">
                        <b-form-group>
                          <label>Từ giờ:</label>
                          <date-picker
                            placeholder="Từ giờ"
                            class="form-control form-control-sm"
                            :config="dpConfigs.time"
                            v-model="mainModel.startTime"
                          ></date-picker>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group>
                          <label>Đến giờ:</label>
                          <date-picker
                            placeholder="Đến giờ"
                            class="form-control form-control-sm"
                            :config="dpConfigs.time"
                            v-model="mainModel.endTime"
                          ></date-picker>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col>
                    <b-form-group>
                      <label>Số lượt tối đa (0 = không giới hạn)</label>
                      <b-input-group>
                        <b-form-input
                          class="input-style text-right"
                          size="sm"
                          v-model="mainModel.maxUse"
                          placeholder="Nhập số lượt tối đa"
                          v-mask="mask"
                        ></b-form-input>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6">
                    <b-form-group>
                      <label>Ưu tiên:</label>
                      <b-form-select
                        size="sm"
                        v-model="mainModel.priority"
                        :options="listPriority"
                        required
                        value-field="id"
                        text-field="name"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label>Trạng thái:</label>
                      <b-form-select
                        size="sm"
                        v-model="mainModel.status"
                        :options="listStatus"
                        required
                        value-field="id"
                        text-field="name"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <span
                      >Số lượt đã sử dụng:
                      {{ convertPrice(mainModel.used) }}</span
                    >
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <h4>Sản phẩm</h4>
            <div class="separator separator-dashed my-5"></div>

            <b-row>
              <b-col md-4>
                <b-form-group id="form-group-categories">
                  <label for="input-categories">Danh mục</label>
                  <simple-multi-select
                    v-model="mainModel.categoryIds"
                    :options="categories"
                    :placeholder="'Chọn danh mục'"
                    :optionLabel="'name'"
                  >
                  </simple-multi-select>
                </b-form-group>
              </b-col>
              <b-col></b-col>
            </b-row>

            <b-row>
              <b-form-group id="input-group-search" class="col-md-12 mt-4 pr-0">
                <label for="input-code">Tìm sản phẩm:</label>
                <b-col md="12" class="row pr-0">
                  <b-col md="6" class="p-0">
                    <Autosuggest
                      :model="searchProductFor"
                      :suggestions="filteredOptionsProductFor"
                      @change="onInputChangeProductFor"
                      @select="onSelectedProductFor"
                      aria-describedby="input-src-store"
                      size="sm"
                      :limit="10"
                      placeholder="tên sản phẩm"
                    >
                      <template #custom="{ suggestion }">
                        <div>
                          <span>{{ suggestion.item.productName }}</span>
                        </div>
                      </template>
                    </Autosuggest>
                  </b-col>
                  <b-col md="2" class="p-0">
                    <b-form-select
                      id="select-product"
                      v-model="selectTypeSearch"
                      :options="listTypeSearch"
                      size="sm"
                      value-field="id"
                      text-field="name"
                      disabled-field="notEnabled"
                      @change="debounceInputFor"
                    ></b-form-select>
                  </b-col>
                  <b-col md="2" class="p-0">
                    <b-form-select
                      id="select-product"
                      v-model="selectTypeOfProduct"
                      :options="listTypeOfProduct"
                      size="sm"
                      value-field="id"
                      text-field="name"
                      disabled-field="notEnabled"
                      @change="debounceInputFor"
                    ></b-form-select>
                  </b-col>
                  <b-col md="2" class="pr-0">
                    <div class="d-flex justify-content-end">
                      <b-button
                        class="btn btn-secondary font-weight-bolder btn-sm"
                        @click="showModalImportProduct"
                      >
                        <i style="font-size: 1rem" class="far fa-file-excel"></i
                        >Nhập SP từ excel
                      </b-button>
                    </div>
                  </b-col>
                </b-col>
              </b-form-group>
            </b-row>
            <b-row>
              <b-col cols="12" md="12">
                <div>
                  <b-table
                    class="table-bordered table-hover col-md-12"
                    :fields="fields"
                    :items="mainModel.details"
                    v-if="renderComponent"
                    :per-page="10"
                    :current-page="currentPageTable"
                    id="my-table-product"
                  >
                    <template v-slot:cell(originalPrice)="row">
                      <span class="text-right">{{
                        convertPrice(row.item.originalPrice)
                      }}</span>
                    </template>
                    <template v-slot:cell(sellingPrice)="row">
                      <span class="text-right">{{
                        convertPrice(row.item.sellingPrice)
                      }}</span>
                    </template>
                    <template v-slot:cell(discountedPrice)="row">
                      <span class="text-right">{{
                        convertPrice(row.item.discountedPrice)
                      }}</span>
                    </template>
                    <template v-slot:cell(discountAmount)="row">
                      <div @change="onCalculateDiscountedPrice(row.item)">
                        <BInputNumber
                          size="sm"
                          v-model="row.item.discountAmount"
                          placeholder="Nhập mức chiết khấu"
                          class="text-right"
                        />
                      </div>
                    </template>
                    <template v-slot:cell(discountType)="row">
                      <b-form-select
                        v-model="row.item.discountType"
                        size="sm"
                        @change="onCalculateDiscountedPrice(row.item)"
                      >
                        <b-form-select-option value="1">
                          Tiền ($)
                        </b-form-select-option>
                        <b-form-select-option value="2">
                          Phần trăm (%)
                        </b-form-select-option>
                      </b-form-select>
                    </template>
                    <template v-slot:cell(actions)="row">
                      <v-icon
                        small
                        class="text-danger"
                        @click="
                          showDeleteAlert(
                            modalTitle,
                            modalDesc,
                            row.item,
                            confirmDelete,
                          )
                        "
                        v-b-tooltip
                        title="Xóa"
                        >mdi-delete</v-icon
                      >
                    </template>
                  </b-table>
                  <b-pagination
                    v-show="mainModel.details.length > 10"
                    v-model="currentPageTable"
                    :total-rows="rowsTable"
                    :per-page="10"
                    aria-controls="my-table-product"
                    align="right"
                  ></b-pagination>
                </div>
              </b-col>
              <b-col cols="6" md="4"></b-col>
            </b-row>
          </b-container>
        </b-form>
        <b-modal
          ref="import-product-modal"
          hide-footer
          title="Thêm sản phẩm từ Excel"
          id="modal-prevent-closing"
          size="lg"
        >
          <b-row>
            <b-col>
              <div class="form-group">
                <span>
                  <b>Bước 1:</b> Vui lòng chuẩn bị dữ liệu mẫu từ file excel
                  đúng format, hoặc có thể tải ở
                  <a :href="urlExcel">đây</a>
                </span>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <span> <b>Bước 2:</b> Tiến hành Import </span>
              <div style="padding-top: 15px">
                <b-form-file
                  placeholder="Hãy chọn một tập tin"
                  @change="selectFile"
                  v-model="file"
                  ref="file-input"
                  class="mb-2"
                ></b-form-file>
              </div>
            </b-col>
          </b-row>
          <b-container class="mt-4 pl-0 pr-0" v-show="importItems.length > 0">
            <h5>
              Danh sách sản phẩm từ file excel( Tổng:
              {{ importItems.length }} sản phẩm)
            </h5>
            <b-table
              id="my-table"
              class="myTable"
              responsive
              bordered
              hover
              :fields="fieldImport"
              :items="importItems"
              :per-page="10"
              :current-page="currentPage"
            >
              <template v-slot:cell(sellingPrice)="row">
                <div style="text-align: end">
                  <span v-text="convertPrice(row.item.sellingPrice)"></span>
                </div>
              </template>
              <template v-slot:cell(sellingDiscountPrice)="row">
                <div style="text-align: end">
                  <span
                    v-text="convertPrice(row.item.sellingDiscountPrice)"
                  ></span>
                </div>
              </template>

              <template v-slot:cell(discountAmount)="row">
                <div style="text-align: end">
                  <span v-text="convertPrice(row.item.discountAmount)"></span>
                </div>
              </template>
              <template v-slot:cell(discountType)="row">
                <span v-if="row.item.discountType === 1">$</span>
                <span v-else-if="row.item.discountType === 2">%</span>
              </template>
            </b-table>
            <b-pagination
              v-show="importItems.length > 10"
              v-model="currentPage"
              :total-rows="rows"
              :per-page="10"
              aria-controls="my-table"
            ></b-pagination>
          </b-container>
          <b-button
            style="fontweight: 600; width: 70px"
            variant="primary"
            size="sm"
            @click="validData"
            >Lưu</b-button
          >
          <b-button
            style="margin-left: 10px; font-weight: 600; width: 70px"
            variant="secondary"
            size="sm"
            @click="hideModalImportProduct"
            >Hủy</b-button
          >
        </b-modal>
      </template>
      <template v-slot:foot>
        <b-container>
          <b-row>
            <b-button
              v-if="checkPermission('DISCOUNT_UPDATE')"
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              type="submit"
              @click="onSubmit"
              >Lưu</b-button
            >
            <b-button
              v-if="checkPermission('DISCOUNT_UPDATE')"
              style="margin-left: 10px; font-weight: 600; width: 70px"
              variant="secondary"
              size="sm"
              @click="onBack"
              >Hủy</b-button
            >
            <b-button
              v-if="!checkPermission('DISCOUNT_UPDATE')"
              style="font-weight: 600; width: 70px"
              variant="secondary"
              size="sm"
              @click="onBack"
              >Hủy</b-button
            >
            <b-col></b-col>
            <b-col></b-col>
            <b-col></b-col>
          </b-row>
        </b-container>
      </template>
    </KTCodePreview>
  </div>
</template>
<style scoped>
.multi-select-wrapper {
  flex: 1 1 auto;
}

.center-label label {
  margin-bottom: 0px;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import moment from 'moment';
import { validationMixin } from 'vuelidate';
import { required, minValue } from 'vuelidate/lib/validators';
import { cmdUrl } from '@/utils/apiUrl';
import { reject, map } from 'lodash';
import {
  currencyMask,
  unMaskPrice,
  makeToastSuccess,
  makeToastFaile,
  convertPrice,
} from '@/utils/common';
import debounce from 'debounce';
import { TIME_TRIGGER, URL_IMPORT_PRODUCT_DISCOUNT } from '@/utils/constants';
import { DISCOUNT_AMOUNT_TYPE } from '@/utils/enum';
import xlsx from 'xlsx';
import { checkPermission } from '@/utils/saveDataToLocal';
import { DP_CONFIG } from '@/utils/date';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import SimpleMultiSelect from '@/view/base/multiselect/SimpleMultiSelect.vue';
import { showDeleteAlert } from '@/utils/sweet-alert2';
import BInputNumber from '@/view/base/bootstrap/BInputNumber.vue';

export default {
  mixins: [validationMixin],
  data() {
    return {
      modalTitle: 'Xóa sản phẩm!',
      modalDesc: 'Bạn có chắc muốn xóa sản phẩm này không ?',
      categories: [],
      renderComponent: true,
      id: '',
      dpConfigs: DP_CONFIG,
      mask: currencyMask,
      mainModel: {
        id: 0,
        name: null,
        description: null,
        startAt: null,
        endAt: null,
        details: [],
        storeIds: [],
        categoryIds: [],
        status: true,
        priority: '1',
        maxUse: 0,
        startTime: '00:00',
        endTime: '23:59',
        used: 0,
      },
      fields: [
        {
          key: 'productCode',
          label: 'Mã',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '10%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'barcode',
          label: 'Mã vạch',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '10%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'productName',
          label: 'Tên SP',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '25%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'sellingPrice',
          label: 'Giá bán (gốc)',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '10%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'discountedPrice',
          label: 'Giá bán (ck)',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '10%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'discountAmount',
          label: 'Chiết khấu',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '10%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'discountType',
          label: 'Kiểu CK',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '10%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'actions',
          label: '',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '5%',
          },
        },
      ],
      listProductFor: [],
      filteredOptionsProductFor: [],
      optionsProductFor: [],
      listProduct: [],
      filteredOptionsProduct: [],
      optionsProduct: [],
      searchProductAccessory: '',
      accessoryItems: [],
      accessoryProductItems: [],
      searchProductFor: '',
      stores: [],
      listStatus: [
        {
          id: true,
          name: 'Hoạt động',
        },
        {
          id: false,
          name: 'Không hoạt động',
        },
      ],
      listPriority: [
        {
          id: '1',
          name: 'Số 1 (cao nhất)',
        },
        {
          id: '2',
          name: 'Số 2',
        },
        {
          id: '3',
          name: 'Số 3',
        },
        {
          id: '4',
          name: 'Số 4',
        },
        {
          id: '5',
          name: 'Số 5',
        },
        {
          id: '6',
          name: 'Số 6',
        },
        {
          id: '7',
          name: 'Số 7',
        },
        {
          id: '8',
          name: 'Số 8',
        },
        {
          id: '9',
          name: 'Số 9',
        },
        {
          id: '10',
          name: 'Số 10',
        },
      ],
      listTypeSearch: [
        {
          id: 1,
          name: 'Sản phẩm IMEI',
        },
        {
          id: 2,
          name: 'Sản phẩm',
        },
      ],
      selectTypeSearch: 1,
      listTypeOfProduct: [
        {
          id: 0,
          name: 'Tất cả sản phẩm',
        },
        {
          id: 1,
          name: 'Sản phẩm cha',
        },
        {
          id: 2,
          name: 'Sản phẩm con',
        },
        {
          id: 3,
          name: 'Sản phẩm độc lập',
        },
        {
          id: 4,
          name: 'Sản phẩm cha + độc lập',
        },
        {
          id: 5,
          name: 'Sản phẩm con + độc lập',
        },
      ],
      selectTypeOfProduct: 0,
      isSearching: false,
      importItems: [],
      file: null,
      excelList: [],
      fieldImport: [
        {
          key: 'productCode',
          label: 'Mã sản phẩm',
        },
        {
          key: 'barCode',
          label: 'Mã vạch',
        },
        {
          key: 'productName',
          label: 'Tên sản phẩm',
        },
        {
          key: 'sellingPrice',
          label: 'Giá bán( gốc)',
        },
        {
          key: 'sellingDiscountPrice',
          label: 'Giá bán( ck)',
        },
        {
          key: 'discountAmount',
          label: 'Chiết khấu',
        },
        {
          key: 'discountType',
          label: 'Kiểu chiết khấu',
        },
      ],
      currentPage: 1,
      urlExcel: URL_IMPORT_PRODUCT_DISCOUNT,
      currentPageTable: 1,
    };
  },
  validations: {
    mainModel: {
      startAt: {
        required,
        minValue: minValue(0),
      },
      name: {
        required,
      },
    },
  },
  components: {
    Autosuggest,
    KTCodePreview,
    SimpleMultiSelect,
    BInputNumber,
  },
  computed: {
    rows() {
      return this.importItems.length;
    },
    rowsTable() {
      return this.mainModel.details.length;
    },
  },
  mounted() {
    let titleName = this.checkPermission('DISCOUNT_UPDATE')
      ? 'Chỉnh sửa chương trình chiết khấu'
      : 'Chi tiết chương trình chiết khấu';
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Chiết khấu ', route: '/discount-programs' },
      {
        title: titleName,
      },
    ]);
  },
  created() {
    this.id = this.$route.query.id;
    this.getDiscountProgram();
    this.fetchStore();
    this.getCategories();
  },
  methods: {
    convertPrice,
    showDeleteAlert,
    getCategories() {
      ApiService.query('category').then(({ data }) => {
        if (data.status === 1) {
          this.categories = data.data.list_caterogy;
        }
      });
    },
    forceRerender() {
      this.renderComponent = false;

      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    fetchStore() {
      this.stores = [];
      const url = 'stores?limit=1000&page=1';
      const method = 'get';

      ApiService[method](url).then((response) => {
        const { list_store } = response.data.data;
        this.stores = list_store.map((store) => {
          return {
            ...store,
            suggestionName: store.shortName + ' ( ' + store.name + ')',
          };
        });
      });
    },
    getDiscountProgram() {
      if (!this.id) {
        return;
      }
      const endpoint = cmdUrl.DiscountProgramUrl.byId.replace('{id}', this.id);
      ApiService.get(endpoint).then((res) => {
        this.mainModel = res.data.data;
        this.mainModel.storeIds = this.mainModel.storeIds || [];
        this.mainModel.categoryIds = this.mainModel.categoryIds || [];
        this.mainModel.startAt = this.mainModel.startAt
          ? moment(this.mainModel.startAt).format('DD/MM/YYYY')
          : null;
        this.mainModel.endAt = this.mainModel.endAt
          ? moment(this.mainModel.endAt).format('DD/MM/YYYY')
          : null;
        this.mainModel.details.forEach((item) => {
          item.discountedPrice = this.onCalculateDiscountedPrice(item);
        });
      });
    },
    updateItem(item) {
      this.accessoryItems.forEach((element, index) => {
        if (element.productId === item.productId) {
          this.accessoryItems[index].importPrice = item.importPrice;
        }
      });
    },
    confirmDelete(deleteItem) {
      this.mainModel.details = reject(this.mainModel.details, (product) => {
        return product.productId == deleteItem.productId;
      });
    },
    fetchProductFor(textSearch) {
      this.isSearching = true;
      this.listProduct = [];
      this.optionsProductFor = [];
      ApiService.get(
        `productSearch/search-by-type?searchProduct=${textSearch}&typeSearch=${this.selectTypeSearch}&typeOfProduct=${this.selectTypeOfProduct}`,
      ).then(({ data }) => {
        const products = data.data;
        this.listProduct = products;
        products.map((element) => {
          this.optionsProductFor.push(element);
        });
        this.filteredOptionsProductFor = [...this.optionsProductFor];
        this.isSearching = false;
        if (textSearch !== this.searchProductFor) {
          let nextTextSearch = this.searchProductFor;
          this.fetchProductFor(nextTextSearch);
        }
      });
    },
    onSelectedProductFor(option) {
      this.searchProductFor = '';

      let item = {
        productId: option.item.productId,
        productName: option.item.productName,
        productCode: option.item.productCode,
        originalPrice: option.item.originalPrice,
        sellingPrice: option.item.sellingPrice,
        barcode: option.item.barCode,
        discountAmount: 0,
        discountType: '1',
      };

      item.discountedPrice = this.onCalculateDiscountedPrice(item);
      this.mainModel.details.push(item);
    },
    onInputChangeProductFor(text) {
      this.searchProductFor = text;

      this.debounceInputFor();
    },
    onBack() {
      history.back();
    },
    onCalculateDiscountedPrice(item) {
      let result = 0;
      const discountAmount = parseFloat(unMaskPrice(item.discountAmount));
      const sellingPrice = parseFloat(item.sellingPrice);
      if (Number(item.discountType) === DISCOUNT_AMOUNT_TYPE.PERCENT) {
        result =
          sellingPrice - ((sellingPrice * discountAmount) / 100).toFixed(0);
      } else {
        result = sellingPrice - discountAmount;
      }
      item.discountedPrice = result;
      this.forceRerender();
      return result;
    },
    validate() {
      if (this.$v.mainModel.$anyError) {
        return true;
      }
      return false;
    },
    mapData() {
      this.mainModel.startAt = moment(
        this.mainModel.startAt,
        'DD/MM/yyyy',
      ).toDate();

      let params = Object.assign({}, this.mainModel);
      params.storeIds = map(params.storeIds, 'id').join();
      params.categoryIds = map(params.categoryIds, 'id').join();
      params.startAt = params.startAt
        ? moment(params.startAt, 'DD/MM/YYYY').valueOf()
        : '';
      params.endAt = params.endAt
        ? moment(params.endAt, 'DD/MM/YYYY').valueOf()
        : '';
      params.createdAt = params.createdAt
        ? moment(params.createdAt, 'DD/MM/YYYY').valueOf()
        : '';
      // Convert discount amount
      params.details.forEach((item) => {
        item.discountAmount = unMaskPrice(item.discountAmount);
        item.discountProgramId = params.id;
      });
      params.maxUse = params.maxUse ? unMaskPrice(params.maxUse) : 0;

      delete params.used;

      return params;
    },
    create(params) {
      ApiService.post(cmdUrl.DiscountProgramUrl.root, params)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            makeToastSuccess(message);
            history.back();
          } else {
            makeToastFaile(message);
          }
        })
        .catch((err) => {
          const message = err.response.data.message;
          makeToastFaile(message);
        });
    },
    update(params) {
      ApiService.patch(
        cmdUrl.DiscountProgramUrl.root + `/${this.mainModel.id}`,
        params,
      )
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            makeToastSuccess(message);
            history.back();
          } else {
            makeToastFaile(message);
          }
        })
        .catch((err) => {
          const message = err.response.data.message;
          makeToastFaile(message);
        });
    },
    onSubmit() {
      if (this.validate()) return;

      const params = this.mapData();
      if (this.id) {
        this.update(params);
      } else {
        this.create(params);
      }
    },
    debounceInputFor: debounce(function () {
      let textSearch = this.searchProductFor;
      if (!this.isSearching) {
        this.fetchProductFor(textSearch);
      }
    }, TIME_TRIGGER),
    selectFile(event) {
      this.importItems = [];
      const files = event.target.files[0];
      this.file = files;
      if (!files) {
        return;
      } else if (!/\.(xls|xlsx)$/.test(files.name.toLowerCase())) {
        this.file = null;
        this.clearFiles();
        return alert(
          'Định dạng của file tải lên không chính xác. Vui lòng tải lên file có đuôi .xls hoặc .xlsx',
        );
      }
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const XLSX = xlsx;
          const workbook = XLSX.read(data, {
            type: 'binary',
          });
          const wsname = workbook.SheetNames[0]; // Take the first sheet，wb.SheetNames[0] :Take the name of the first sheet in the sheets
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            blankRows: false,
          }); // Generate JSON table content，wb.Sheets Get the data of the first sheet
          const excelList = []; // Clear received data
          // Edit data
          for (var i = 0; i < ws.length; i++) {
            excelList.push(ws[i]);
          } // At this point, you get an array containing objects that need to be processed
          this.excelList = excelList;
          this.excelList.map((element) => {
            let data = {
              productName: element['Tên sản phẩm']
                ? element['Tên sản phẩm']
                : '',
              productCode: element['Mã sản phẩm'] ? element['Mã sản phẩm'] : '',
              barCode: element['Mã vạch'] ? element['Mã vạch'] : '',
              sellingPrice: element['Giá bán( gốc)']
                ? element['Giá bán( gốc)']
                : 0,
              sellingDiscountPrice: element['Giá bán( ck)']
                ? element['Giá bán( ck)']
                : 0,
              discountAmount: element['Chiết khấu'] ? element['Chiết khấu'] : 0,
              discountType:
                element['Kiểu chiết khấu'] === 'Theo phần trăm' ? 2 : 1,
            };
            this.importItems.push(data);
          });
        } catch (e) {
          return alert('Read failure!');
        }
      };
      fileReader.readAsBinaryString(files);
    },
    clearFiles() {
      this.$refs['file-input'].reset();
    },
    showModalImportProduct() {
      this.importItems = [];
      this.excelList = [];
      this.$refs['import-product-modal'].show();
    },
    hideModalImportProduct() {
      this.$refs['import-product-modal'].hide();
    },
    validData() {
      let data = {
        listProduct: this.importItems,
      };
      ApiService.post('tradeInProgram/valid-import-product-discount', data)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            makeToastSuccess(message);
            response.data.data.forEach((element) => {
              this.mainModel.details.push(element);
            });
            this.hideModalImportProduct();
          } else {
            makeToastFaile(message);
          }
        })
        .catch((err) => {
          const message = err;
          makeToastFaile(message);
        });
    },
    checkPermission(condition) {
      return checkPermission(condition);
    },
  },
};
</script>
