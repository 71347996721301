<template>
  <div class="custom-multiselect-container">
    <vue-simple-multi-select
      v-model="value"
      :options="options"
      :placeholder="placeholder"
      :option-label="optionLabel"
      :max="maxItems"
      :classes="classes"
      :max-results="maxItems"
    ></vue-simple-multi-select>
  </div>
</template>

<script>
import VueSimpleMultiSelect from 'vue-simple-multi-select';

export default {
  components: {
    VueSimpleMultiSelect,
  },
  props: {
    value: {
      required: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: () => '',
    },
    optionLabel: {
      required: false,
      type: String,
      default: () => null,
    },
    maxItems: {
      required: false,
      type: Number,
      default: () => 30,
    },
    classes: {
      type: Object,
      required: false,
      default: () => {
        return {
          active: 'active',
          wrapper: 'multi-select-wrapper',
          searchWrapper: 'search-wrapper',
          searchInput: 'search-input',
          pill: 'pill',
          required: 'required',
          dropdown: 'dropdown',
        };
      },
    },
  },
  data() {
    return {};
  },
  created() {},
  watch: {},
};
</script>

<style scoped>
.custom-multiselect-container /deep/ .search-wrapper div {
  border: 1px solid #e5eaee;
}

.custom-multiselect-container {
  width: 100%;
}

.multi-select-wrapper {
  display: inline;
  height: calc(1.35em + 1.1rem + 2px);
  font-size: 1rem;
  line-height: 1.35;
}

.search-input {
  color: black;
}
.custom-multiselect-container /deep/ .pill span:first-child {
  padding: 0.5em;
  font-size: 0.925rem;
}
</style>